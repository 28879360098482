import { InputNumericMasked } from '@odo/components/elements/form-fields';
import type { ChangeInput } from '@odo/contexts/product-editor';
import {
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';

const RebateDiscount = ({
  screen,
  disabled,
}: {
  screen: NonNullable<ChangeInput['screen']>;
  disabled?: boolean;
}) => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  return (
    <InputNumericMasked
      label="Rebate (%)"
      value={currentProduct.rebateDiscount?.string || ''}
      disabled={disabled}
      type="decimal"
      onChange={rebateDiscount => {
        change({
          fieldId: 'rebateDiscount',
          label: 'Rebate (%)',
          screen,
          apply: to => (to.rebateDiscount = rebateDiscount),
        });
      }}
    />
  );
};

export default RebateDiscount;
