import type { EditorProductInterface } from '@odo/types/portal';
import type { ReactNode } from 'react';

export enum Status {
  valid = 'valid',
  warning = 'warning',
  error = 'error',
}

export interface Validator {
  validate: (product: EditorProductInterface) => boolean;
  /**
   * Default: false
   * Set to true if you want to show the error/warning
   * when the validate function returns true instead of when it returns false.
   */
  invertValidation?: boolean;
  message: ReactNode;
  status: Status.error | Status.warning;
}

export enum Screen {
  buyerAndSupplier = 'buyer-and-supplier',
  product = 'product',
  conditionsAndCategory = 'conditions-and-category',
  priceAndCustomOptions = 'price-and-custom-options',
  shippingAndInventory = 'shipping-and-inventory',
  imagesAndVideos = 'images-and-videos',
  summary = 'summary',
}

/**
 * We generate a template literal type of our enum so that we can use it without importing everywhere.
 */
export type ScreenId = `${Screen}`;
