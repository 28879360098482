import { useChangeProduct } from '@odo/contexts/product-editor';
import type { EditorProductSizeInfoImage } from '@odo/types/portal';
import Button from '@odo/components/elements/button';
import {
  ImageActionDownload,
  ImageActionOpenInNewTab,
} from '@odo/screens/deal/editor/widgets/images/image-actions';
import {
  TbTrash as IconTrash,
  TbTrashOff as IconTrashOff,
} from 'react-icons/tb';
import Tooltip from '@odo/components/widgets/tooltip';
import { isSizeInfoImageLoaded } from '@odo/screens/deal/editor/product/helpers';

const SizeInfoImageActionDelete = ({
  image,
}: {
  image: EditorProductSizeInfoImage;
}) => {
  const change = useChangeProduct();

  return (
    <Tooltip
      showDelay={1250}
      content={() => (image.shouldDelete ? 'Cancel Delete' : 'Delete Image')}
    >
      <Button
        hue="grey"
        variant="flat"
        px={0}
        py={0}
        onClick={() => {
          if (image.file) {
            change({
              // NOTE: same fieldId as the upload so the changes override each other
              fieldId: `sizeInfo.${image.id}.file`,
              label: `Cancel ${image.label} Upload`,
              screen: 'product',
              apply: to =>
                (to.sizeInfo = {
                  ...to.sizeInfo,
                  [image.id]: {
                    id: image.id,
                    label: image.label,
                    url: image.url,
                    filePath: image.filePath,
                  },
                }),
            });
          } else {
            const shouldDelete = !image.shouldDelete;
            change({
              fieldId: `sizeInfo.${image.id}.shouldDelete`,
              label: shouldDelete
                ? `Delete ${image.label} Image`
                : `Cancel ${image.label} Delete`,
              screen: 'product',
              apply: to =>
                (to.sizeInfo = {
                  ...to.sizeInfo,
                  [image.id]: {
                    id: image.id,
                    label: image.label,
                    url: image.url,
                    filePath: image.filePath,
                    shouldDelete,
                  },
                }),
            });
          }
        }}
      >
        {image.shouldDelete ? (
          <IconTrashOff size={14} />
        ) : (
          <IconTrash size={14} />
        )}
      </Button>
    </Tooltip>
  );
};

const SizeInfoImageActions = ({
  image,
}: {
  image: EditorProductSizeInfoImage;
}) => (
  <>
    {isSizeInfoImageLoaded(image) && (
      <>
        <SizeInfoImageActionDelete image={image} />
        <ImageActionDownload image={image} />
        <ImageActionOpenInNewTab image={image} />
      </>
    )}
  </>
);

export default SizeInfoImageActions;
