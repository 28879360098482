import Button from '@odo/components/elements/button';
import { RadioCard } from '@odo/components/elements/card';
import { Flex, Grid } from '@odo/components/elements/layout';
import { Heading, Text } from '@odo/components/elements/typography';
import type {
  CustomOptionTree,
  EditorProductInterface,
} from '@odo/types/portal';
import { cssColor } from '@odo/utils/css-color';
import { useCallback, useState } from 'react';
import type { Attribute } from '@odo/contexts/attributes';
import { createDraft } from '@odo/data/product/draft/cache';
import { useNavigate } from 'react-router-dom';
import { duplicateEditorCustomOptions } from '@odo/data/custom-options/utils';
import { Switch } from '@odo/components/elements/form-fields';
import { FiAlertCircle as IconAlert } from 'react-icons/fi';
import Tooltip from '@odo/components/widgets/tooltip';
import { duplicateAsRepeat } from '@odo/data/product/duplication';
import { BASE_PATH } from '@odo/screens/deal/editor/constants';

type DuplicationType = 'repeat' | 'template';

const DuplicationTypeSelector = ({
  isSelected,
  selectType,
  heading,
  description,
}: {
  isSelected: boolean;
  selectType: () => void;
  heading: string;
  description: string;
}) => (
  <RadioCard isSelected={isSelected} selectCard={selectType}>
    <Heading fontSize={[1, 2]}>{heading}</Heading>
    <Text>{description}</Text>
  </RadioCard>
);

const Duplicate = ({
  product,
  customOptions,
  areAttributesLoaded,
  attributes,
  close,
  openInNewTab,
}: {
  product: EditorProductInterface;
  customOptions: CustomOptionTree[];
  areAttributesLoaded: boolean;
  attributes: Attribute[];
  close: () => void;
  openInNewTab?: boolean;
}) => {
  const navigate = useNavigate();

  const [duplicationType, setDuplicationType] = useState<
    DuplicationType | undefined
  >();
  const [showingTemplateOptions, setShowTemplateOptions] = useState(false);

  const [isDuplicating, setIsDuplicating] = useState(false);

  const confirmDuplicate = useCallback(
    async (duplicationType: DuplicationType) => {
      setIsDuplicating(true);

      try {
        // TODO: BP-866: switch to duplicateAsTemplate
        const newProduct =
          duplicationType === 'template'
            ? duplicateAsRepeat({ product, attributes, asTemplate: true })
            : duplicateAsRepeat({ product, attributes });
        const tmpId = await createDraft(newProduct);

        duplicateEditorCustomOptions({
          customOptions,
          productId: `tmp-${tmpId}`,
        });

        const dest = `${BASE_PATH}/tmp-${tmpId}/buyer-and-supplier`;
        if (openInNewTab) {
          // open the new deal in a new tab and close the duplication
          window.open(dest, '_blank');
        } else {
          // redirect to the drafts editor page and then close the duplication in case
          navigate(dest);
        }
        close();
      } catch (e) {
        setIsDuplicating(false);
      }
    },
    [product, customOptions, attributes, navigate, close, openInNewTab]
  );

  return (
    <Flex flexDirection="column" gap={[3, 4]}>
      <Flex flexDirection="column" gap={[2, 3]}>
        {!showingTemplateOptions && (
          <>
            <Text textAlign="center">
              How would you like to duplicate this deal?
            </Text>

            <Flex gap={[2, 3]}>
              <DuplicationTypeSelector
                isSelected={duplicationType === 'repeat'}
                selectType={() => setDuplicationType('repeat')}
                heading="Repeat"
                description="Run the same deal again"
              />

              <DuplicationTypeSelector
                isSelected={duplicationType === 'template'}
                selectType={() => setDuplicationType('template')}
                heading="Template"
                description="Re-use some of the fields"
              />
            </Flex>
          </>
        )}

        {/* NOTE: template options and flow are likely to change before it's complete, but this sets a good base */}
        {showingTemplateOptions && (
          <>
            <Text textAlign="center">
              What fields would you like to re-use?
            </Text>
            <Grid
              width="450px"
              gap={[2, 3]}
              px={[2, 3]}
              gridTemplateColumns="1fr auto"
            >
              <Flex gap={[2, 3]} alignItems="center">
                <Tooltip
                  showDelay={250}
                  content={() => 'Supplier, Rebate (%)...'}
                >
                  <IconAlert size={16} color={cssColor('palette-blue')} />
                </Tooltip>
                <Heading fontSize={2} lineHeight={1}>
                  Supplier Info
                </Heading>
              </Flex>
              <Switch width="3.5em" />

              <Flex gap={[2, 3]} alignItems="center">
                <Tooltip
                  showDelay={250}
                  content={() => 'All Images & Videos, Photographed By Studio'}
                >
                  <IconAlert size={16} color={cssColor('palette-blue')} />
                </Tooltip>
                <Heading fontSize={2} lineHeight={1}>
                  Images & Videos
                </Heading>
              </Flex>
              <Switch width="3.5em" />

              <Flex gap={[2, 3]} alignItems="center">
                <Tooltip
                  showDelay={250}
                  content={() => 'Cost, Price, Retail Price, Admin Cost, etc.'}
                >
                  <IconAlert size={16} color={cssColor('palette-blue')} />
                </Tooltip>
                <Heading fontSize={2} lineHeight={1}>
                  Pricing
                </Heading>
              </Flex>
              <Switch width="3.5em" />

              <Flex gap={[2, 3]} alignItems="center">
                <Tooltip showDelay={250} content={() => 'All Custom Options'}>
                  <IconAlert size={16} color={cssColor('palette-blue')} />
                </Tooltip>
                <Heading fontSize={2} lineHeight={1}>
                  Custom Options
                </Heading>
              </Flex>
              <Switch width="3.5em" />

              <Flex gap={[2, 3]} alignItems="center">
                <Tooltip
                  showDelay={250}
                  content={() =>
                    'Weight, Length, Width, Height, Customer Delivery Time, etc.'
                  }
                >
                  <IconAlert size={16} color={cssColor('palette-blue')} />
                </Tooltip>
                <Heading fontSize={2} lineHeight={1}>
                  Shipping & Inventory
                </Heading>
              </Flex>
              <Switch width="3.5em" />
            </Grid>
          </>
        )}
      </Flex>

      <Flex justifyContent="space-between" gap={3}>
        <Button
          hue="grey"
          variant="flat"
          onClick={() =>
            showingTemplateOptions ? setShowTemplateOptions(false) : close()
          }
        >
          {showingTemplateOptions ? 'Back' : 'Cancel'}
        </Button>

        {/* TODO: BP-866: update copy and logic to handle template selection */}
        <Button
          hue="blue"
          variant="solid"
          loading={isDuplicating}
          disabled={!duplicationType || !areAttributesLoaded}
          onClick={() => {
            if (!duplicationType) return;
            // if (duplicationType === 'template' && !showingTemplateOptions) {
            //   setShowTemplateOptions(true);
            //   return;
            // }
            confirmDuplicate(duplicationType);
          }}
        >
          {duplicationType === 'template' && !showingTemplateOptions
            ? 'Confirm'
            : 'Confirm'}
        </Button>
      </Flex>
    </Flex>
  );
};

export default Duplicate;
